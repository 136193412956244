@import '../partials';

.hidden {
    display: none;
}

.no-list-style {
    list-style: none;
}

.default-cursor {
    cursor: default;
}

/* * * * * * * * * *
 * IMAGE MODIFIERS *
** * * * * * * * * */
.circle {
    border-radius: 50%;
    overflow: hidden;
}

/* * * * * * * * *
 * HR MODIFIERS  *
** * * * * * * * */
hr.collapsed {
    margin: $spacing-s 0;

    &--extra {
        margin: $spacing-xs 0;
    }
}

/* * * * * * * * * *
 * TEXT MODIFIERS  *
** * * * * * * * * */
.no-text-decoration {
    text-decoration: none;
}

button.underline {
    text-decoration: underline;
    &:not([disabled]):hover,
    &:active {
        text-decoration: none;
    }
}

a.inverted,
button.inverted {
    text-decoration: none;
    &:not([disabled]):hover,
    &:active {
        text-decoration: underline;
    }
}

.text-shadow {
    text-shadow: 0 0 2px $black;
}

.strike {
    text-decoration: line-through;
}

.ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.small {
    font-size: rem(12);
    line-height: (20 / 13);
}

.tiny {
    font-size: rem(11);
    line-height: 1.2;
}

.line-height {
    &--double {      line-height: 2;         }
    &--small {       line-height: (20/13);   }
    &--smaller {     line-height: 1.35;      }
    &--tiny {        line-height: 1.2;       }
    &--collapsed {   line-height: 1;         }
}

.align {
    &--left {    text-align: left;   }
    &--center {  text-align: center; }
    &--right {   text-align: right;  }
    &--justify { text-align: justify;}
}

/* * * * * * * * * * *
 * WEIGHT MODIFIERS  *
** * * * * * * * * * */
.w {
    &--300 {         font-weight: 300;       }
    &--400 {         font-weight: 400;       }
    &--500 {         font-weight: 500;       }
    &--700 {         font-weight: 700;       }
}

.normal,
.regular {
    font-weight: 300;
}

.bold,
.strong {
    font-weight: 400;
}

.bolder,
.stronger {
    font-weight: 500;
}

.extra-bold,
.extra-strong {
    font-weight: 700;
}


/* * * * * * * * * * * * * *
 * ELEMENT SIZE MODIFIERS  *
 * * * * * * * * * * * * * */
.full-width {
    width: 100%;
}

.full-heigth {
    height: 100%;
}

/* * * * * * * * * * * * *
 * RESPONSIVE MODIFIERS  *
** * * * * * * * * * * * */
// mobile, mobile-only
// portrait, portrait-only
// desktop, desktop-only
// landscape, landscape-only

@media (max-width: ($bp-portrait - 1px)) {
    .portrait,
    .portrait-only {
        display: none;
    }
}

@media (min-width: $bp-portrait) {
    .mobile-only {
        display: none;
    }
}

@media (max-width: ($bp-desktop - 1px)) {
    .desktop,
    .desktop-only {
        display: none;
    }
}

@media (min-width: $bp-desktop) {
    .portrait-only {
        display: none;
    }
}

@media (max-width: ($bp-landscape - 1px)) {
    .landscape,
    .landscape-only {
        display: none;
    }
}

@media (min-width: $bp-landscape) {
    .desktop-only {
        display: none;
    }
}
