@import '../../scss/partials';

.home{
    &__filters{
        margin-top: $spacing-l;
    }

    &__locations{
        &__card{
            position: relative;
            border-radius: $border-radius;
            margin-right: $spacing-m;
            flex: 0 0 rem(230);
            overflow: hidden;
            height: 240px;
            cursor: pointer;
        }

        &__title{
            background-color: rgba(0,0,0,0.5);
            padding: $spacing-xs;
            border-radius: $border-radius;
            color: $white;
            position: absolute;
            left: 50%;
            top: 80%;
            transform: translate(-50%, -50%);
        }
    }

}
