@mixin responsive-size($min, $max, $min_vp: $bp-responsive-size-min, $max_vp: $bp-responsive-size-max, $prop: font-size, $unit: px){
    $min_dim: $min + $unit;
    $max_dim: $max + $unit;

    $min_vp_dim: strip-unit($min_vp);
    $max_vp_dim: strip-unit($max_vp);

    @if unitless($min_vp_dim) {
        $min_vp_dim: $min_vp_dim + $unit;
    }

    @if unitless($max_vp_dim) {
        $max_vp_dim: $max_vp_dim + $unit;
    }

    //output
    #{$prop}: $min_dim;

    @media (min-width: $min_vp_dim) {
        $c_1: $max - $min;
        $c_2: strip-unit($max_vp - $min_vp);

        #{$prop}: calc(#{$min_dim} + #{$c_1} * ((100vw - #{$min_vp_dim}) / #{$c_2}));
    }

    @media (min-width: $max_vp_dim) {
        #{$prop}: $max_dim;
    }
}

@mixin appearance($value: none){
    -o-appearance: $value;
    -ms-appearance: $value;
    -moz-appearance: $value;
    -webkit-appearance: $value;
    appearance: $value;
}

@mixin transition($prop: all, $duration: $transition-short, $easing: ease-out){
    transition: $prop $duration $easing;
}

@mixin boxShadow($x: 0, $y: 0, $blur: $default-shadow-blur, $spread: 0, $color: $shadow){
    box-shadow: $x $y $blur $spread $color;
}
