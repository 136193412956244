.columns{
    &__main{
        max-width: $columns-main-width;
        margin-left: auto;
        margin-right: auto;

        &--spaced{
            margin: $content-spacing auto;
        }
    }

    &__sidebar{
        max-width: $columns-sidebar-width;
        margin-left: auto;
        margin-right: auto;

        &--spaced{
            margin: $content-spacing auto;
        }
    }
}

@media (min-width: $bp-s){
    .columns{
        display: flex;
        justify-content: space-between;

        &__main{
            flex: 1 1 $columns-main-width-bp-s;
            padding: $spacing-l $content-spacing-desktop $spacing-l 0;
            margin: 0;
            max-width: none;

            &:only-child{
                margin-right: $columns-sidebar-width-bp-s;
            }
        }

        &__sidebar{
            position: relative;
            flex: 0 0 $columns-sidebar-width-bp-s;
            padding: $spacing-l 0;
            margin: 0;
        }
    }
}
