@import '../../../../scss/partials';

.backdrop{

    display: none;

    position: fixed;

    width: 100vw;
    height: 100vh;

    left: 0;
    top: 0;

    z-index: 10000;

    background-color: $backdrop;

    animation: backdrop-show $transition-short ease-out reverse;
    transition: display 1ms $transition-short;

    &--open{

        display: block;

        animation: backdrop-show $transition-short ease-out;
        transition: display 0ms 0ms;

    }

}

@keyframes backdrop-show{
    from{   opacity: 0; }
    to{     opacity: 1; }
}
