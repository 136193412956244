@import '../../scss/partials';

.logo{
    max-width: 130px;
    height: rem($button-size-m);

    &--inline{
        display: inline-block;
        margin-left: 0.2em;
        height: (9.2 / 12 + 0em);
        max-width: 60px;
    }

    &__img{
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        object-position: left center;
    }
}

@supports (object-fit: contain) {
    .logo{
        &__img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
