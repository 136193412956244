h1 {
    font-size: rem(60);
    font-weight: 700;
    line-height: 1.35;
    color: $c-text-dark;
}

h2 {
    font-size: rem(48);
    font-weight: 400;
    line-height: 1.35;
    color: $c-text-dark;
}

h3 {
    font-size: rem(32);
    font-weight: 400;
    line-height: 1.35;
    color: $c-text-dark;
}

h4 {
    font-size: rem(22);
    font-weight: 500;
    line-height: 1.35;
    color: $c-text-black;
}

h5 {
    font-size: rem(16);
    font-weight: 500;
    line-height: 1.2;
    color: $c-text-black;
}

h6 {
    font-size: rem(14);
    font-weight: 500;
    line-height: 1.2;
    color: $c-text-black;
}

small {
    font-size: (12 / $base-font-size-max + 0em);
}

strong {
    font-weight: 500;
}

em {
    font-style: italic;
}

p {
    margin-top: $spacing-s;
    margin-bottom: $spacing-s;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:empty {
        display: none;
    }
}

a {
    color: inherit;

    &:hover,
    &:active {
        text-decoration: none;
    }

    &:focus {
        outline-color: $c-brand-primary-dark;
    }
}
