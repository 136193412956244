@import '../../../scss/partials';

$arrow-width: 24px;
$arrow-height: 8px;
$arrow-offset: rem(14 - 6);
$arrow-offset-top: 3px;
$border-width: 1px;

$popup-max-height: 480px;

.popup {
    position: absolute;
    min-width: rem(270);
    z-index: 1;

    height: 0;
    max-height: $popup-max-height;

    opacity: 0;

    overflow: hidden;

    transition: opacity $transition-medium ease-out,
                top $transition-medium ease-out,
                height 1ms $transition-medium;

    // default: &--bottom
    top: 90%;
    top: calc(100% - 1em);

    &--no-set-width{
        min-width: rem(150);
    }

    &--open {
        height: auto;
        opacity: 1;

        overflow: visible;

        top: 95%;
        top: calc(100% - 0.3em);

        transition: opacity $transition-medium ease-out 1ms,
                    top $transition-medium ease-out 1ms;
    }

    &--top {
        top: auto;
        bottom: 90%;
        bottom: calc(100% - 1em);

        &--open {
            top: auto;
            bottom: 95%;
            bottom: calc(100% - 0.3em);
        }
    }

    &--collapsed {
        min-width: auto;
    }

    &--stretch {
        min-width: 100%;
    }

    &--stretch-maxheight {
        max-height: none;
    }

    &--arrow-left {
        left: 0;
        //
        // &--stretch {
        //     width: auto;
        //     right: 0;
        // }
    }

    &--arrow-center {
        left: 50%;
        transform: translateX(-50%);

        // &--stretch {
        //     width: 100%;
        // }
    }

    &--arrow-right {
        right: 0;
        //
        // &--stretch {
        //     width: auto;
        //     left: 0;
        // }
    }

    &__arrow {
        position: absolute;
        z-index: 1;
        width: $arrow-width;
        height: $arrow-height;
        top: $arrow-offset-top;

        &--right {
            right: $arrow-offset;
        }

        &--left {
            left: $arrow-offset;
        }

        &--center {
            left: 50%;
            transform: translateX($arrow-width / -2);
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            border-style: solid;
            width: 0;
            height: 0;
        }

        &::before {
            top: (-1 * $border-width);
            left: (-1 * $border-width);
            border-width: 0 ($arrow-width / 2 + $border-width) ($arrow-height + $border-width) ($arrow-width / 2 + $border-width);
            border-color: $c-border transparent;
        }

        &::after {
            top: 0;
            left: 0;
            border-width: 0 ($arrow-width / 2) $arrow-height ($arrow-width / 2);
            border-color: $white transparent;
        }

        &--top {
            top: auto;
            bottom: $arrow-offset-top;

            &::before {
                top: $border-width;
                border-width: ($arrow-height + $border-width) ($arrow-width / 2 + $border-width) 0 ($arrow-width / 2 + $border-width);
            }

            &::after {
                border-width: $arrow-height ($arrow-width / 2) 0 ($arrow-width / 2);
            }
        }
    }

    &__content {
        position: relative;

        margin-top: ($arrow-height + $arrow-offset-top - $border-width);

        max-height: ($popup-max-height - ($arrow-height + $arrow-offset-top - $border-width));

        overflow: auto;

        border: $border-width solid $c-border;
        border-radius: $border-radius;
        @include boxShadow();

        &--stretch-maxheight {
            max-height: none;
        }

        &--top {
            margin-top: 0;
            margin-bottom: ($arrow-height + $arrow-offset-top - $border-width);
        }
    }

    &__overflow {
      overflow: visible;
    }

}

@media (max-width: ($bp-xxs - 1px)) {
    .popup {
        &--fullscreen {
            position: fixed;
            top: 0;
            bottom: auto;
            left: 0;

            width: 100vw;

            transform: translateX(0);
            transition: none;

            display: flex;

            &.popup--open {
                height: 100vh;
            }

            .popup {
                &__arrow {
                    display: none;
                }

                &__content {
                    width: 100%;
                    height: 100%;
                    max-height: none !important;
                    flex: 0 0 auto;
                    margin: 0;
                    border: 0;
                    border-radius: 0;
                    box-shadow: none;
                    left: 0;
                    right: 0;
                    transform: none !important;
                }
            }
        }
    }
}
