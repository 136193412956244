@import '../../../scss/partials';

.clamp{
    display: block;
    position: relative;

    &--clamped{
        overflow: hidden;
    }

    &__gradient{
        position: absolute;

        right: 0;
        bottom: 0;

        width: 100%;
        background: linear-gradient(90deg, transparent, $white);

        &::before{
            content: "\a0";
        }
    }

    &__original-content{
        position: absolute;
        width: 100%;
        left: -100vw;
    }
}

.clamp-button{
    margin-top: $spacing-m;
}
