@import '../../../../scss/partials';

.verification-modal {
    &__image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: auto;
        z-index: -1;
        border-radius: $border-radius $border-radius 0 0;
    }

    &__content {
        padding-top: calc((100% * 267 / 386) + 4rem);
    }

    &__button {
        margin-top: 2em;
    }
}
