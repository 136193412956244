@import '../partials';

.grid{
    display: -ms-grid;
}

@supports(display: grid) {
    .grid{
        display: grid;
    }
}