.container {
    width: 100%;
    padding: $spacing-l 0 $spacing-xl 0;
    &--padding {
        &-evenly {
            padding: $spacing-xl 0;
        }
    }
}

.content {
    padding: 0 $content-spacing;
    &--padding {
        padding: $content-spacing;
        &-collapsed { padding: 0; }
        &-vertical { padding: $content-spacing 0; }

        &-bottom {
            padding-bottom: $content-spacing;
            &-double {
                padding-bottom: (2 * $content-spacing);
            }
        }
        &-top {
            padding-top: $content-spacing;
            &-double {
                padding-top: (2 * $content-spacing);
            }
        }
    }

    max-width: $content-width;
    margin: auto;
    &--margin {
        max-width: ($content-width - 2 * $content-spacing);
        margin: $content-spacing;
        &-vertical {
            max-width: $content-width;
            margin-top: $content-spacing;
            margin-bottom: $content-spacing;
            &-double {
                max-width: $content-width;
                margin-top: (2 * $content-spacing);
                margin-bottom: (2 * $content-spacing);
            }
        }
        &-bottom {
            margin-bottom: $content-spacing;
            &-double {
                margin-bottom: (2 * $content-spacing);
            }
        }
        &-top {
            margin-top: $content-spacing;
            &-double {
                margin-top: (2 * $content-spacing);
            }
        }
    }

    &--narrow {
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;

        max-width: $content-width-narrow;
        &--extra {
            max-width: $content-width-narrow-extra;
        }

        &--form {
            max-width: $content-width-narrow-form;
        }
    }

    &--box {
        margin: $content-spacing auto;
        padding: $spacing-m;
        border-radius: $border-radius;
        border: 1px solid $c-border;

        &--small {
            padding: $spacing-s;
        }

        &--compact {
            padding: $spacing-s $spacing-m;
        }

        &--error {
            border-color: $c-error;
        }

        &--valid {
            border-color: $c-valid;
        }
    }

    &--panel {
        width: $content-panel-width;
        max-width: 80%;
        padding: $spacing-xl $spacing-l;

        background-color: $c-background;

        border: 1px solid $gray-3;
        border-bottom-width: 2px;
        border-radius: $border-radius;
    }
}

@media (min-width: $bp-s) {
    .container {
        padding: $spacing-xl 0 $spacing-xxl 0;
        &--padding {
            &-evenly {
                padding: $spacing-xxl 0;
            }
        }
    }

    .content {
        &--panel {
            padding: $spacing-xxl $spacing-xl;
        }
    }
}

@media (min-width: $bp-desktop) {
    .container {
        &--padding {
            &-evenly {
                padding: $spacing-xxxl 0;
            }
        }
    }
    .content {
        padding: 0 $content-spacing-desktop;
        &--padding {
            padding: $content-spacing-desktop;
            &-collapsed { padding: 0; }
            &-vertical { padding: $content-spacing-desktop 0; }

            &-bottom {
                padding-bottom: $content-spacing-desktop;
                &-double {
                    padding-bottom: (2 * $content-spacing-desktop);
                }
            }
            &-top {
                padding-top: $content-spacing-desktop;
                &-double {
                    padding-top: (2 * $content-spacing-desktop);
                }
            }
        }

        max-width: $content-width-desktop;
        &--margin {
            max-width: ($content-width-desktop - 2 * $content-spacing-desktop);
            margin: $content-spacing-desktop;
            &-vertical {
                max-width: $content-width-desktop;
                margin-top: $content-spacing-desktop;
                margin-bottom: $content-spacing-desktop;
                &-double {
                    max-width: $content-width-desktop;
                    margin-top: (2 * $content-spacing-desktop);
                    margin-bottom: (2 * $content-spacing-desktop);
                }
            }
            &-bottom {
                margin-bottom: $content-spacing-desktop;
                &-double {
                    margin-bottom: (2 * $content-spacing-desktop);
                }
            }
            &-top {
                margin-top: $content-spacing-desktop;
                &-double {
                    margin-top: (2 * $content-spacing-desktop);
                }
            }
        }

        &--narrow {
            padding-left: 0;
            padding-right: 0;

            max-width: $content-width-narrow;
            &--extra {
                max-width: $content-width-narrow-extra;
            }
            &--form {
                max-width: $content-width-narrow-form;
            }
        }

        &--box {
            margin: $content-spacing-desktop auto;
            padding: $spacing-xl;
            &--small {
                padding: $spacing-m;
            }
            &--compact {
                padding: $spacing-m $spacing-xl;
            }

            &--no-margin {
                margin: 0;
            }
        }

        &--panel {
            padding: $spacing-xxl $spacing-xl;
            max-width: $content-panel-width;
        }
    }
}
