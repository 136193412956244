:root{
    @include responsive-size($base-font-size, $base-font-size-max);
}

*, *::before, *::after{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

*:focus{
    outline: 0;
}
