/* * * * * * * * *
 * NAMED COLORS  *
 * * * * * * * * */
$white:             #fefefe;
$white-faded:       rgba($white, 0.2);
$black:             #16161d;

$gray-1:            #f2f4f9;
$gray-2:            #e5e5e5;
$gray-3:            #d8d8d8;
$gray-4:            #d3d3d3;
$gray-5:            #989898;
$gray-6:            #4a4a4a;

$gray-blue:         #383f47;
$gray-blue-dark:    #121b27;

$red:               #ff6666;
$red-dark:          darken($red, 5%);

$green-light:       #86d0c6;
$green-light-faded: rgba($green-light, 0.4);
$green:             #2bcca7;
$green-faded:       rgba($green, 0.4);
$green-extra-faded: rgba($green, 0.05);
$green-dark:        #32b899;

$yellow:            #eac207;
$yellow-faded:      rgba($yellow, 0.1);

$blue:              #2392f6;
$orange:            #ffbf42;

/* * * * * * * * * * *
 * INTERFACE COLORS  *
 * * * * * * * * * * */
$c-background:      $white;

$c-text:            $gray-blue;

$c-text-lighter:    $gray-6;
$c-text-light:      $gray-5;
$c-text-lightest:   $gray-4;

$c-text-dark:       $gray-blue-dark;
$c-text-black:      $black;
$c-text-white:      $white;

$c-input-focus:     $gray-blue-dark;
$c-input-disabled:  $gray-2;

$c-border:          $gray-2;
$c-border-dark:     $gray-4;

$c-error:           $red;
$c-error-text:      $white;
$c-valid:           $green;
$c-valid-text:      $white;
$c-success:         $blue;
$c-success-text:    $white;
$c-warning:         $orange;
$c-warning-text:    $white;

$shadow:            rgba($black, 0.1);

$backdrop:          rgba($black, 0.35);
