@import '../Dropdown.module';

.item {
    padding: $spacing-xs;

    background-color: $white;
    border-bottom: 1px solid $c-border;

    cursor: pointer;

    &:last-child {
        border-bottom: 0;
    }

    &--disabled {
        cursor: default;
    }

    &--focus {
        background-color: $gray-1;
    }

    &__content {
        padding: $spacing-s $spacing-m;
        background-color: $white;
        border-radius: $border-radius;

        &--focus {
            background-color: $gray-2;
        }

        &--selected {
            background-color: $c-brand-primary;
            color: $c-brand-primary-text;

            &.item__content--focus {
                background-color: $c-brand-primary-dark;
                color: $c-brand-primary-dark-text;
            }
        }

        &--disabled {
            opacity: 0.5;
        }

        &--collapsed {
            padding: $spacing-xs;
            text-align: center;
            line-height: 1.5;
        }
    }
}
