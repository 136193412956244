@import '../../../scss/partials';

.circle{
    position: relative;
    display: inline-block;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;

    &::before,
    &::after{
        content: "";
        position: absolute;
        border-style: solid;
        border-color: inherit;
        left: 50%;
        top: 50%;
    }

    &--success{
        border-color: $c-valid;

        &::before{
            border-width: 0 2px 2px 0;
            width: (40 / 140 * 100 + 0%);
            height: (80 / 140 * 100 + 0%);
            transform: translate(-50%, -65%) rotate(45deg);
        }

        &::after{
            display: none;
        }
    }

    &--error{
        border-color: $c-error;

        &::before,
        &::after{
            width: (80 / 140 * 100 + 0%);
            height: 2px;
            border-width: 2px 0 0 0;
        }

        &::before{
            transform: translateX(-50%) rotate(45deg);
        }

        &::after{
            transform: translateX(-50%) rotate(-45deg);
        }
    }
}

.flash-buttons{
    margin-top: $spacing-l;

    &__button{
        width: 50%;
        padding: 0 $spacing-m;

        &:first-child{
            padding-left: 0;
        }

        &:last-child{
            padding-right: 0;
        }

        &:only-child{
            margin: 0 auto;
        }
    }
}
