@import '../../../scss/partials';

.notifications{
    position: fixed;
    z-index: 9999;

    top: 110px; // magic number?
    right: 0;

    width: 100%;
    max-width: rem(360) + $spacing-xl + $spacing-xl;

    padding: 0 $spacing-xl;
}

.notification{
    position: relative;

    margin-bottom: $spacing-m;
    padding: $spacing-m $spacing-l;

    border-radius: $border-radius;

    overflow: hidden;

    cursor: pointer;

    left: 0;
    opacity: 1;
    transform: scale(1);

    @include boxShadow();
    @include transition(all, $transition-medium, ease-in-out);

    animation: show-notification $transition-long;

    &:hover:not(.notification--closed){
        opacity: 0.85;
        transform: scale(0.99);
    }

    &--success{
        background-color: $c-valid;
    }

    &--error{
        background-color: $c-error;
    }

    &--warning{
        background-color: $c-warning;
    }

    &--general{
        background-color: $c-success;
    }

    &--closed{
        opacity: 0;
        height: 0;
        padding: 0 $spacing-l;
        margin-bottom: 0;
        transform: scale(0);
    }

    &__icon{
        margin-right: $spacing-m;
        flex-shrink: 0;
    }

    &__content{
        margin-top: 0;
    }
}

@keyframes show-notification{
    from {
        left: 5rem;
        opacity: 0;
    }
    to {
        left: 0;
        opacity: 1;
    }
}
