@import '../../../../scss/partials';

.tags{
    &--card{
        position: absolute;
        left: 0;
        top: 0;
        padding: $spacing-l;
        z-index: 1;
    }

    &--list{
        margin-top: 0.3em;
    }

    &__loader{
        width: 5em;
        height: 1.6em;
    }
}

.header{
    &__title{
        flex: 0 1 auto;
        padding-right: $spacing-m;
        min-width: 0;

        &__title{
            &__loader{
                max-width: 100%;
                width: 12.5em;
            }
        }

        &__location{
            margin: 0;
            &__loader{
                max-width: 100%;
                width: 20em;
            }
        }

        &--compact{
            padding-right: 0;
        }
    }

    &__price{
        flex: 0 0 auto;

        &__unit{
            font-weight: 400;
            display: block;
        }
    }

    &__company{

        &__logo{
            max-height: rem(50);
            object-fit: contain;
        }
    }
}

.availability-tag{
    display: none;

    &--list{
        display: block;        
    }

    &--card{
        display: block;
        margin-bottom: 0;
    }

    &__tag{
        &::before{
            content: '';
            display: inline-block;
            width: 0.75em;
            height: 0.75em;
            margin-right: 0.6em;
            border-radius: 50%;
        }

        &--available::before{ background-color: $c-valid; }
        &--unavailable::before{ background-color: $c-error; }
    }
}

.footer{
    margin-top: $spacing-s;

    &__props{
        margin-top: $spacing-l;

        &--map{
            margin-top: $spacing-m;
            padding-top: $spacing-m;
            border-top: 1px solid $c-border;
        }
    }

    &__prop{
        display: inline-block;
        vertical-align: bottom;

        & + &{
            margin-left: $spacing-m;
        }

        &:nth-child(3){
            display: none;
        }

        &__icon{
            display: block;

            margin: 0 auto $spacing-xs auto;

            width: rem(30);
            height: rem(30);
            object-fit: contain;
        }

        &__name{
            display: block;
            max-width: rem(110);

            &--map{
                max-width: rem(70);
            }
        }
    }

}

@media (min-width: $bp-portrait){

    .footer{

        &__prop{

            &:nth-child(3){
                display: inline-block;
            }

        }

    }

}
