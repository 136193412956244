@import '../../../scss/partials';

.tag{
    display: inline-block;

    width: auto;
    min-width: rem(50);
    max-width: rem((80 + 20));
    height: rem(23);

    line-height: rem(23);
    text-align: center;

    border-radius: $border-radius-l;

    background-color: $c-brand-primary-faded;
    color: $c-brand-primary-faded-text;

    &--small{
        min-width: rem(40);
        max-width: none;
        height: rem(19);
        line-height: rem(19);
    }

    &--grow{
        max-width: none;
    }

    &__button{
        padding: 0 rem(8);
        cursor: default;
    }

    &__content{
        display: block;
        font-size: rem(15);
        font-weight: 300;

        &--small{
            font-size: rem(11);
            font-weight: 400;
        }
    }

}
