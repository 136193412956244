@import '../../../../../scss/partials';

.autocomplete{

    &__item{

        &:not(:last-child){
            margin-bottom: $spacing-xs;
        }
        &--hilighted{
            background-color: $gray-1;
        }

        &__content{
            margin-left: $spacing-s;
        }

        &__button{
            padding: 0 $spacing-xs;
        }

    }

}
