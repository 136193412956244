@import '../../../../scss/partials';

.loader-container{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.loader{
    width: 150px;
    height: 150px;

    background-color: $c-background;
    border-radius: $border-radius-l;

    &__dots{
      width: 80px;
    }
    
    &__dots__dot{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: transparent;
        
        border: 1px solid $c-brand-primary;
        position: relative;
        
        &:before {
          content: "";
          width: 18px;
          height: 18px;
          border-radius: 50%;
          display: block;
          background: $c-brand-primary;
          position: absolute;
          transform: scale(.3);
          top: 0;
          left: 0;
          transform-origin: center;
        }
        
        &:nth-child(1){
        
          &:before{
            animation: fill 1800ms infinite;
          }
        }
        &:nth-child(2){
    
          &:before{
            animation: fill 1800ms infinite 300ms;
          }
        }
        &:nth-child(3){
    
          &:before{
            animation: fill 1800ms infinite 600ms;
          }
        }
    }
}

@keyframes fill{
    50%{
        transform: scale(.8);
    }
}