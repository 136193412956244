@function strip-unit($val){
    @if type-of($val) == 'number' and not unitless($val){
        @return $val / ($val * 0 + 1);
    }
    @return $val;
}

@function fix($val, $digits: 2, $mode: 'round'){
    @if(type-of($val) == 'number' and type-of($digits) == 'number' and unitless($digits)){
        $n: 1;

        @if($digits > 0){
            @for $i from 1 through $digits{
                $n: $n * 10;
            }
        }

        @if($mode == 'round'){
            @return round($val * $n) / $n;
        }

        @if($mode == 'ceil'){
            @return ceil($val * $n) / $n;
        }

        @if($mode == 'floor'){
            @return floor($val * $n) / $n;
        }
    }

    @warn 'Something went wrong in the fix() function';
    @return $val;
}

@function calcRelativeUnit($value, $base, $digits, $unit: false){
    $value: fix(($value / $base), $digits);
    @if ($unit){
        @return $value + $unit;
    }
    @return $value;
}

@function rem($value, $base: $base-font-size-max, $digits: 2, $unit: true){
    $outputUnit: false;
    @if ($unit) {
        $outputUnit: 0rem;
    }
    @return calcRelativeUnit($value, $base, $digits, $outputUnit);
}

@function em($value, $base: $base-font-size-max, $digits: 2, $unit: true){
    $outputUnit: false;
    @if ($unit) {
        $outputUnit: 0em;
    }
    @return calcRelativeUnit($value, $base, $digits, $outputUnit);
}
