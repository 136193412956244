// FONT SIZES
$base-font-size: 13;
$base-font-size-max: 15;

// SPACING
$spacing-xxs:   rem(2.5);
$spacing-xs:    rem(5);
$spacing-s:     rem(10);
$spacing-m:     rem(14);
$spacing-l:     rem(20);
$spacing-xl:    rem(30);
$spacing-xxl:   rem(40);
$spacing-xxxl:  rem(50);
$spacing-xxxxl: rem(60);

// ELEMENT DIMENSIONS
$min-element-width: rem(360);

// CONTENT DIMENSIONS
$content-spacing:           $spacing-s;
$content-spacing-desktop:   $spacing-l;

$content-width: rem(1000 + 2 * strip-unit($content-spacing));
$content-width-desktop: rem(1000 + 2 * strip-unit($content-spacing-desktop));

$content-width-narrow: rem(680);
$content-width-narrow-extra: rem(460);
$content-width-narrow-form: rem(550);

$content-panel-width: rem(540 + 2 * strip-unit($spacing-l));

// COLUMN DIMENSIONS
$columns-main-width: rem(560 + 2 * strip-unit($content-spacing));
$columns-main-width-bp-s: rem(580 + strip-unit($content-spacing-desktop));
$columns-sidebar-width: rem(560 + 2 * strip-unit($content-spacing));
$columns-sidebar-width-bp-s: rem(320);

// PAGE DIMENSIONS
$page-sidemenu-width: rem(280);

// MODAL DIMENSIONS
$modal-width:       rem(480);
$modal-width-large: rem(680);

// BUTTON SIZES
$button-size-xxs:20;
$button-size-xs: 28;
$button-size-s:  32;
$button-size:    38;
$button-size-m:  48;
$button-size-l:  52;
$button-size-xl: 62;

// INPUT SIZES
$input-size-s:  48;
$input-size:    56;

// BORDER RADIUS
$border-radius-s:   2px;
$border-radius:     3px;
$border-radius-l:   4px;

// TRANSITION TIMES
$transition-short:  80ms;
$transition-medium: 2 * $transition-short;
$transition-long:   3 * $transition-short;

// OTHER DIMENSIONS
$default-shadow-blur:       20px;

// BREAKPOINTS
$bp-xxs:                    500px;
$bp-xs:                     640px;
$bp-s:                      960px;
$bp-m:                      1280px;
$bp-l:                      1600px;
$bp-xl:                     1920px;

$bp-portrait:               768px;
$bp-desktop:                1024px;
$bp-landscape:              1080px;

$bp-responsive-size-min:    $bp-xs;
$bp-responsive-size-max:    $bp-m;
