@import '../../../../scss/partials';

.loading-bar{
    position: relative;
    display: inline-block;
    overflow: hidden;
    height: 1em;
    background-color: $gray-1;

    vertical-align: middle;

    width: 100%;
    max-width: 15em;
    min-width: 5em;

    &::after{
        position: absolute;
        display: block;
        content: "";
        width: auto; height: auto;
        top: 0; right: 0; bottom: 0; left: 0;
        background: linear-gradient(to right, rgba($white, 0) 10%, transparentize($white, 0.3) 50%, rgba($white, 0) 90%);
        animation: loadingAnimation (8 * $transition-long) linear infinite;
    }

    &--auto{
        width: auto;
    }

    &--darker{
        background-color: $gray-2;
    }

    &--small{
        width: 10px;
        min-width: 10px;
    }

    &--full-width{
        max-width: none;
        height: 1.5em;
    }

    &--fillout{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        max-width: none;
    }

    &--no-animation{
        &::after{
            display: none;
        }
    }
}

@keyframes loadingAnimation{
    from{ transform: translate3d(-100%, 0, 0); }
    to{ transform: translate3d(100%, 0, 0); }
}
