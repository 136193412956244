@import '../../../scss/partials';

.payment-accounts{
    &__list{
        margin-bottom: $spacing-l;
    }

    &__item{
        position: relative;

        &::after{
            content: "";
            position: absolute;
            width: auto;
            height: 1px;
            left: $spacing-m;
            right: $spacing-m;
            bottom: 0;
            background-color: $c-border;
        }

        &:last-child{
            &::after{
                display: none;
            }
        }
    }

    &__label{
        width: 100%;
        height: auto;
        margin-bottom: 0;
        padding: $spacing-l $spacing-m;

        &:hover{
            background-color: $gray-1;
        }
    }

    &__image{
        display: inline-block;
        vertical-align: middle;

        width: rem(36);
        height: rem(36);

        &[src]{
            object-fit: contain;
            border: 1px solid $c-border;
        }
    }

    &__name{
        flex: 1 0 auto;
        margin: 0 $spacing-m;
    }

}
