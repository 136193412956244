@import '../../scss/partials';

.bar{
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid $gray-5;
    @include boxShadow();
}

.invoices{
    width: 210mm;
    margin: $spacing-xxl auto;
    border: 1px solid $gray-3;
    border-radius: $border-radius;
    overflow: hidden;
    @include boxShadow();
}

.invoice{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: $white;

    // min-height: 297mm;
    min-height: 267mm;

    font-size: rem(14);
    line-height: (20 / 12);

    border-bottom: 1px dashed $gray-3;

    &:last-child{
        border-bottom: 0;
    }

    &__section{
        padding: $spacing-xl $spacing-xxl;
        flex: 0 0 auto;
    }
}


.header{
    padding-top: $spacing-xxl;
    background-color: $gray-1;

    &__section{
        display: flex;
        justify-content: space-between;

        &--top{
            align-items: flex-start;
        }

        &--bottom{
            align-items: center;
            margin-top: $spacing-xxl;
        }
    }

    &__title{
        height: rem(40);
        line-height: rem(40);
    }

    &__content{
        margin-top: 0;
    }

    &__logo{
        display: block;
        margin-left: auto;
        margin-right: 0;
        width: rem(130);
    }
}

.body{
    flex-grow: 1;

    &__content{
        padding: $spacing-l 0;
        border-bottom: 1px solid $gray-2;

        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
}

.footer{
    padding-top: 0;
    font-size: rem(12);
    line-height: rem(18);

    &__content{
        padding-top: $spacing-xl;
        border-top: 1px solid $gray-2;

        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__logo{
        display: inline-block;
        height: rem(12);
        width: auto;
        vertical-align: middle;

        img{
            width: auto;
        }
    }

    &__text{
        display: inline-block;
        vertical-align: middle;
        margin-left: $spacing-xl;
    }
}

.table{
    display: block;

    &__section{
        display: block;
    }

    &__row{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        padding-top: $spacing-m;

        &:last-child{
            padding-bottom: $spacing-m;
        }

        &--large{
            margin-top: $spacing-l;
            padding-top: $spacing-l;
            padding-bottom: $spacing-l;

            border-top: 1px solid $c-text-black;
            border-bottom: 1px solid $c-text-black;
        }
    }

    &__cell{
        display: inline-block;
        flex: 0 0 auto;

        &__content{
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &--item{
            width: calc(100% - 2cm);
        }

        &--price{
            width: 2cm;
        }

        &:first-child{
            padding-right: $spacing-l;
        }

        &:last-child{
            text-align: right;
        }
    }

    &__head{
        border-bottom: 1px solid $gray-2;
    }

    &__body{
        border-bottom: 1px solid $gray-2;
    }

    &__footer{
        padding-top: $spacing-s;
    }
}

@media print {
    @page { 
        margin: 0;
    }
    .bar{
        display: none;
    }

    .invoices{
        margin: 0;
        border: 0;
        border-radius: 0;
        overflow: auto;
        box-shadow: none;
        padding: 0 1.5cm;
    }

    .invoice{
        border-bottom: 0 !important;
        background-color: transparent;

        page-break-inside: avoid;
        page-break-after: always;
        break-inside: avoid;
        break-after: always;

        &__section{
            padding-left: 0;
            padding-right: 0;
        }
    }

    .header{
        padding-top: 0;
        margin-top: 1cm;
        border-bottom: 1px solid $gray-4;
        background-color: transparent;
    }

    .body{
        
    }

    .footer{
        padding-bottom: 0;

        &__content{
            border-color: $gray-4;
        }
    }
}
