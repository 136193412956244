@import '../../../../../scss/partials';

.search-dropdown{
    z-index: 20;
    position: absolute;

    top: 100%;
    right: 0;
    left: 0;

    width: auto;

    margin-top: 6px;

    border-style: solid;
    border-color: $c-border;
    border-radius: $border-radius;

    background-color: $white;

    @include boxShadow();

    opacity: 0;
    border-width: 0;
    padding: 0 $spacing-l;
    height: 0;
    transform: translateY(-6px);
    overflow: hidden;

    transition: all 1ms $transition-medium,
                opacity $transition-medium,
                transform $transition-medium;

    &--open{
        opacity: 1;
        border-width: 1px;
        padding: $spacing-xl $spacing-l;
        height: auto;
        transform: translateY(0);

        transition: all 1ms,
                    opacity $transition-short 1ms,
                    transform $transition-short 1ms;
    }

}
