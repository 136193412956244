@import '../../../../../scss/partials';

.types{

    margin-top: $spacing-m;
    line-height: rem(50);

    &__item{
        display: inline-block;
        margin-right: $spacing-m;

        &:last-child{
            margin-right: 0;
        }
    }
}

.search-section{

    &__title{
        margin: $spacing-l 0 $spacing-s 0;
    }

    &__item{

        &:not(:last-child){
            margin-bottom: $spacing-xs;
        }

        &:hover{
            background-color: $gray-1;
        }

        &__content{
            margin-left: $spacing-s;
        }

        &__button{
            position: relative;
            padding: 0 $spacing-xs;
        }

        &__dots{
            display: block;
            position: absolute;
            right: $spacing-xs;
            top: 0;
        }

    }

}
