/* * * * * * * * * *
 * COLOR MODIFIERS *
 * * * * * * * * * */
.c {
    &--text { color: $c-text; }

    &--text-disabled { color: $c-input-disabled; }
    &--text-lighter { color: $c-text-lighter; }
    &--text-light { color: $c-text-light; }
    &--text-lightest { color: $c-text-lightest; }
    &--text-dark { color: $c-text-dark; }

    &--text-black { color: $c-text-black; }
    &--text-white { color: $c-text-white; }

    &--error { color: $c-error; }
    &--valid { color: $c-valid; }
    &--success { color: $c-success; }
    &--warning { color: $c-warning; }

    &--brand { color: $c-brand-primary; }
    &--brand-faded { color: $c-brand-primary-faded; }
    &--brand-light { color: $c-brand-primary-light; }
    &--brand-dark { color: $c-brand-primary-dark; }

    &--brand-secondary { color: $c-brand-secondary; }
    &--brand-secondary-faded { color: $c-brand-secondary-faded; }
    &--brand-secondary-light { color: $c-brand-secondary-light; }
    &--brand-secondary-dark { color: $c-brand-secondary-dark; }
}
.b {
    &--white {   background-color: $white;   }
    &--gray-1 {  background-color: $gray-1;  }
    &--gray-2 {  background-color: $gray-2;  }
    &--gray-3 {  background-color: $gray-3;  }
    &--gray-4 {  background-color: $gray-4;  }
    &--gray-5 {  background-color: $gray-5;  }
    &--gray-6 {  background-color: $gray-6;  }
    &--black {   background-color: $black;   }

    &--background {
        background-color: $c-background;
    }

    &--error {
        background-color: $c-error;
        color: $c-error-text;
    }
    &--valid {
        background-color: $c-valid;
        color: $c-valid-text;
    }
    &--success {
        background-color: $c-success;
        color: $c-success-text;
    }
    &--warning {
        background-color: $c-warning;
        color: $c-warning-text;
    }
    &--brand {
        background-color: $c-brand-primary;
        color: $c-brand-primary-text;
    }
    &--brand-faded {
        background-color: $c-brand-primary-faded;
        color: $c-brand-primary-faded-text;
    }
    &--brand-light {
        background-color: $c-brand-primary-light;
        color: $c-brand-primary-light-text;
    }
    &--brand-dark {
        background-color: $c-brand-primary-dark;
        color: $c-brand-primary-dark-text;
    }
    &--brand-secondary {
        background-color: $c-brand-secondary;
        color: $c-brand-secondary-text;
    }
    &--brand-secondary-faded {
        background-color: $c-brand-secondary-faded;
        color: $c-brand-secondary-faded-text;
    }
    &--brand-secondary-light {
        background-color: $c-brand-secondary-light;
        color: $c-brand-secondary-light-text;
    }
    &--brand-secondary-dark{
        background-color: $c-brand-secondary-dark;
        color: $c-brand-secondary-dark-text;
    }
}
