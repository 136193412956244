@import '../../../scss/partials';

.horizontal-scroll{
    position: relative;

    &__container{
        position: relative;
        width: 100%;

        -ms-overflow-style: none;

        &--can-scroll{
            cursor: ew-resize;
            cursor: grab;
            cursor: -webkit-grab;
        }

        &--dragging{

            &::after{
                position: absolute;
                content: "";
                z-index: 1;

                width: auto; height: auto;
                top: 0; right: 0; bottom: 0; left: 0;
            }
        }
    }

    &__items{
        white-space: nowrap;
        overflow: auto;
        overflow-x: hidden;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;

        *::selection{
            background-color: transparent;
        }
    }

    &__overlay,
    &__button{
        position: absolute;

        &--hidden{
            pointer-events: none;
        }
    }

    &__overlay{
        top: 0;
        bottom: 0;

        height: auto;
        width: rem(90);

        &--hidden{
            display: none;
        }

        &--prev{
            left: 0;
            background-image: linear-gradient(to right, $white 50%, rgba($white, 0));
        }
        &--next{
            right: 0;
            background-image: linear-gradient(to left, $white 50%, rgba($white, 0));
        }
    }

    &__button{
        top: 50%;
        z-index: 1;

        opacity: 1;

        transition: opacity $transition-medium $transition-short;

        &--prev{
            right: 100%;
            transform: translate(100%, -50%);
            transform: translate(calc(100% - #{0.5 * $content-spacing}), -50%);
        }

        &--next{
            left: 100%;
            transform: translate(-100%, -50%);
            transform: translate(calc(-100% + #{0.5 * $content-spacing}), -50%);
        }

        &--hidden{
            opacity: 0;
        }
    }
}

@media (min-width: $bp-desktop) {
    .horizontal-scroll{
        &__items{
            overflow-x: hidden;
        }
    }
}

@supports(overflow: hidden) {
    .horizontal-scroll{

        &__container{
            overflow: hidden;
        }
    }
}
