@import '../../../scss/partials';

.input {
    display: inline-block;

    font-size: rem(15);
    font-family: inherit;
    font-weight: 300;
    line-height: (28 / $base-font-size);
    height: rem($input-size);

    width: 100%;
    max-width: rem(180);

    padding: 0 $spacing-m;
    margin-bottom: $spacing-l;

    background-color: $white;
    color: $c-text;
    border: 1px solid $c-border;

    border-radius: $border-radius-s;
    box-shadow: none; // <= fix ugly firefox styling...

    @include transition();

    // States
    &:focus,
    &--focus {
        border-color: $c-input-focus;
        outline: 0;
    }

    &[disabled],
    &--disabled,
    &[readonly],
    &--readonly {
        color: $gray-5;
        background-color: $gray-1;
        border-color: $gray-3;
    }

    &::placeholder {
        color: $c-text-light;
    }

    &--invalid {
        &, &:focus {
            border-color: $c-error;
        }
    }

    // Modifiers
    &--s {
        height: rem($input-size-s);
    }

    &--collapsed {
        margin: auto;
    }

    &--stretch {
        max-width: none;
    }

    &--no-border {
        border: 0;
    }

    // Radiobuttons and checkboxes
    &[type="radio"],
    &[type="checkbox"] {
        display: none;
    }

    // Textarea
    &--textarea {
        display: block;
        resize: none;
        font-size: rem(14);
        line-height: (20 / $base-font-size);
        padding: $spacing-m;
        height: rem(4 * 20) + 2 * $spacing-m;
    }
}

.select-wrapper {
    line-height: 1;
    width: 100%;
    max-width: rem(180);

    &--stretch {
        max-width: none;
    }
}

.label {
    display: block;

    color: $black;
    font-weight: 400;
    line-height: (26 / $base-font-size);

    width: 100%;
    max-width: rem(180);

    padding-left: $border-radius-s;
    margin-bottom: $spacing-xs;

    &--toggle,
    &--button {
        display: inline-block;
        font-weight: 300;
        color: $gray-6;
        padding-left: 0;
    }

    &--toggle {
        height: rem(24);
        line-height: rem(24);
        padding-right: $spacing-s;
        max-width: none;
    }

    &--dropdown {
        cursor: pointer;
    }

    &--disabled,
    &--disabled[for] {
        color: $gray-4;
        cursor: default;
    }

    &--select {
        cursor: pointer;
    }

    &--stretch {
        max-width: none;
    }

    &--right {
        padding-right: 0;
    }

    &--button {
        margin-bottom: 0;
    }

    &__content {
        &--required {
            &::after {
                content: "*";
                margin-left: 3px;
            }
        }

        &--toggle {
            display: block;
            position: relative;

            vertical-align: middle;

            padding-left: rem(28);

            &::before,
            &::after {
                position: absolute;
            }

            &::before {
                left: 0;
                top: 50%;

                width: rem(20);
                height: rem(20);

                display: inline-block;
                content: "";
                background-color: $white;
                border: 1px solid $c-border;

                transform: translateY(-50%);

                input[type="checkbox"] + & {
                    border-radius: $border-radius-s;
                }

                input[type="radio"] + & {
                    border-radius: 50%;
                }

            }

            &::after {
                opacity: 0;
                @include transition(opacity);

                .label:not(.label--disabled):hover > & {
                    opacity: 0.3;
                }
                .input:checked + &,
                .input:checked:hover + & {
                    opacity: 1;
                }

                input[type="checkbox"] + & {
                    content: "";

                    display: block;

                    height: rem(12);
                    width: rem(6);

                    left: rem(10);
                    top: 50%;

                    border-color: $c-brand-primary;
                    border-style: solid;
                    border-width: 0 2px 2px 0;

                    transform: translate(-50%, -60%) rotate(45deg);

                    .label--disabled & {
                        border-color: $gray-4;
                    }
                }

                input[type="radio"] + & {
                    content: "";

                    width: rem(8);
                    height: rem(8);

                    left: rem(6);
                    top: 50%;

                    transform: translateY(-50%);

                    background-color: $c-brand-primary;

                    border-radius: 50%;

                    .label--disabled & {
                        background-color: $gray-4;
                    }
                }
            }

            &.label__content--right {
                padding-left: 0;
                padding-right: rem(28);

                &::before {
                    left: auto;
                    right: 0;
                }

                &::after {
                    input[type="checkbox"] + & {
                        left: auto;
                        right: rem(10);
                        transform: translate(50%, -60%) rotate(45deg);
                    }
                    input[type="radio"] + & {
                        left: auto;
                        right: rem(6);
                    }
                }
            }
        }

        &--button {
            display: inline-block;

            height: rem($button-size);
            line-height: calc(#{rem($button-size)} - 2px);

            padding: 0 $spacing-m;

            border-radius: $border-radius;

            background-color: $white;
            color: $gray-blue;

            border: 1px solid $c-border;

            @include transition();

            &.label__content--auto-height {
                height: auto;
            }

            .input:checked + & {
                border-color: $c-brand-primary;
                background-color: $c-brand-primary;
                color: $c-brand-primary-text;

                &:hover {
                    background-color: $c-brand-primary-faded;
                    color: $c-brand-primary-faded-text;
                }
            }

            &:hover {
                border-color: $c-brand-primary;
            }
        }
    }
}

.description {
    position: absolute;
    white-space: nowrap;
    margin: 0;
    padding: 0 $border-radius-s;
    transform: translateY(-1 * $spacing-l);

    width: 100%;
    max-width: rem(180);

    &--stretch {
        max-width: none;
    }
}
