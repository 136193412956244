@import '../Input.module';

.select {
    //display: none;
}

.wrapper {
    position: relative;
    max-width: none;
    cursor: pointer;
}

.display {
    height: 100%;

    &__icon {
        flex: 0 0 auto;
        width: 1.27rem;
        margin-right: $spacing-s;
    }

    &__value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 auto;
    }

    &__caret {
        flex: 0 0 auto;
        margin-left: $spacing-s;
    }

    &__checkbox {
        flex: 0 0 auto;
        position: relative;
        display: inline-block;
        width: rem(16);
        height: rem(16);

        background-color: $white;
        border: 1px solid $c-border-dark;
        border-radius: $border-radius-s;

        margin-left: $spacing-s;

        &--collapsed {
            margin-left: 0;
            margin-right: $spacing-s;
            vertical-align: sub;
        }

        &--checked {
            border-color: $gray-5;
            &::after {
                content: "";
                display: block;
                height: rem(9.6);
                width: rem(4.8);

                position: absolute;
                left: rem(7);
                top: 50%;

                border-color: $c-brand-primary-dark;
                border-style: solid;
                border-width: 0 2px 2px 0;

                transform: translate(-50%, -60%) rotate(45deg);
            }
        }
    }
}

.list {
    &--collapsed {

    }
}
