@import '../../../scss/partials';

.filter-buttons{

    &__item{
        display: inline-block;
        width: rem(140);
        margin-left: $spacing-m;

        &__loader{
            width: (rem(140) - $spacing-m - $spacing-m);
            height: (rem(100) - $spacing-m - $spacing-m);
        }

        &:first-child{
            margin-left: 0;
        }
    }

    &__label,
    &__label__content{
        width: 100%;
    }

    &__button{
        display: block;
        margin-bottom: $spacing-m;

        &__icon{
            display: block;
            margin: $spacing-m auto $spacing-xs auto;
            width: rem(50);
            height: rem(50);
            object-fit: contain;
        }

        &__content{
            display: block;
        }

    }

}
