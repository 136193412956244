@import '../partials';

// MIXINS
$flex-map: (1, (1/(3/4)), (1/(2/3)), 2, 3, 4, 5);

@mixin flexContainer($baseClass: '.flex') {
    #{$baseClass} {
        display: flex;
        &--inline {      display: inline-flex;           }
        &--row {         flex-direction: row;            }
        &--column {      flex-direction: column;         }
        &--wrap {        flex-wrap: wrap;                }
        &--no-wrap {     flex-wrap: nowrap;              }
        &--justify {
            &-start {    justify-content: flex-start;    }
            &-end {      justify-content: flex-end;      }
            &-center {   justify-content: center;        }
            &-between {  justify-content: space-between; }
            &-around {   justify-content: space-around;  }
        }
        &--align {
            &-start {    align-items: flex-start;        }
            &-end {      align-items: flex-end;          }
            &-center {   align-items: center;            }
            &-baseline { align-items: baseline;          }
        }
//        &--align-content {
//            &-start {    align-content: flex-start;      }
//            &-end {      align-content: flex-end;        }
//            &-center {   align-content: center;          }
//            &-between {  align-content: space-between;   }
//            &-around {   align-content: space-around;    }
//        }
    }
}

@mixin flexColumns($baseClass: '.col', $map: $flex-map) {
    @each $width in $map {
        $widthClass: fix((100 / $width), 0, 'floor');
        $widthPerc: fix((100 / $width), 4) + 0%;

        #{$baseClass}--#{$widthClass} {
            width: ($widthPerc + 0%);
            flex-basis: ($widthPerc + 0%);
        }
    }
}

@mixin flex($modifier: '') {
    @if(str-length($modifier) > 0) {
        @include flexContainer('.flex__#{$modifier}');
        @include flexColumns('.col__#{$modifier}');
    }@else {
        @include flexContainer();
        @include flexColumns();
    }
}

// OUTPUT
@include flex();

@media (min-width: $bp-xxs) {
    @include flex('xxs');
}

@media (min-width: $bp-xs) {
    @include flex('xs');
}

@media (min-width: $bp-s) {
    @include flex('s');
}

@media (min-width: $bp-desktop) {
    @include flex('desktop');
}
