@import '../../../scss/partials';

@mixin button-color ($map: 0, $hoverMap: 0) {
    $properties: ("background-color" "border-color" "color");

    @if type-of($map) == list {
        $index: 0;
        @each $color in $map {
            $index: $index + 1;
            @if type-of($color) == color or type-of($color) == string {
                #{nth($properties, $index)}: $color;
            }
        }
    }

    @if type-of($hoverMap) == list {
        &:not(:disabled):not(.button--disabled){
            &:hover,
            &.button--active{
                $index: 0;
                @each $color in $hoverMap {
                    $index: $index + 1;
                    @if type-of($color) == color or type-of($color) == string {
                        #{nth($properties, $index)}: $color;
                    }
                }
            }
        }
    }
}

.button{
    display: inline-block;

    height: rem($button-size);
    padding: 0 $spacing-m;

    border-width: 1px;
    border-style: solid;
    border-radius: $border-radius;

    font-family: inherit;
    font-size: rem(16);
    font-weight: 400;
    line-height: calc(#{rem($button-size)} - 2px);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;

    white-space: nowrap;

    cursor: pointer;
    box-shadow: none;

    @include transition();

    & + .button{
        margin-left: $spacing-l;
    }

    &:not(:disabled):not(.button--disabled):active,
    &:not(:disabled):not(.button--disabled):focus{
        @include boxShadow(0, 0, 8px);
    }

    &:disabled,
    &--disabled{
        opacity: 0.3;
        cursor: default;
        pointer-events: none;
    }

    // default
    &--transparent{
        @include button-color(
            (transparent transparent inherit),
            (transparent $c-border)
        );

        &.button--border{
            @include button-color(
                (transparent $c-border),
                (transparent $c-border-dark)
            );
        }

        &:not(:disabled):not(.button--disabled):active,
        &:not(:disabled):not(.button--disabled):focus{
            box-shadow: none;
        }
    }

    /* type modifiers */
    &--white{
        @include button-color(
            ($white $white $black),
            ($white $c-border $black)
        );

        &.button--border{
            @include button-color(
                ($white $c-border),
                ($white $c-border-dark)
            );
        }
    }

    &--gray{
        @include button-color(
            ($gray-3 $gray-3 $black),
            ($gray-5 $gray-5)
        );

        &.button--border{
            @include button-color(
                ($gray-3 $gray-5),
                ($gray-5 $gray-6)
            );
        }
    }

    &--primary{
        @include button-color(
            ($c-brand-primary $c-brand-primary $c-brand-primary-text),
            ($c-brand-primary-dark $c-brand-primary-dark $c-brand-primary-dark-text)
        );

        &.button--border{
            @include button-color(
                ($white $c-brand-primary $black),
                ($white $c-brand-primary-dark $black)
            );
        }
    }

    &--warning{
        @include button-color(
            ($c-warning $c-warning $c-warning-text),
            ($c-warning $c-warning)
        );

        &.button--border{
            @include button-color(
                ($white $c-warning $black),
                ($white $c-warning $black)
            );
        }
    }

    &--error{
        @include button-color(
            ($c-error $c-error $c-error-text),
            ($c-error $c-error)
        );

        &.button--border{
            @include button-color(
                ($white $c-error $black),
                ($white $c-error $black)
            );
        }
    }

    &--no-border{
        border: 0;
        border-radius: 0;

        &:active,
        &:not(:disabled):not(.button--disabled):focus{
            @include boxShadow(0, 0, 0);
        }
    }

    &--text{
        font-weight: inherit;
        font-size: inherit;

        vertical-align: baseline;
        text-align: inherit;

        line-height: inherit;
        height: auto;

        padding: 0;
        border: 0;

        border-radius: 0;

        @include button-color(
            (transparent 0 inherit),
            (transparent 0 inherit)
        );

        &:not(:disabled):not(.button--disabled):active,
        &:not(:disabled):not(.button--disabled):focus{
            box-shadow: none;
        }
    }

    &--lite{
        font-size: rem(14);
        font-weight: 300;
    }

    /* size modifiers */
    &--xxs{
        font-size: rem(12);
        line-height: calc(#{rem($button-size-xxs)} - 2px);
        height: rem($button-size-xxs);
    }

    &--xs{
        font-size: rem(14);
        line-height: calc(#{rem($button-size-xs)} - 2px);
        height: rem($button-size-xs);
    }

    &--s{
        line-height: calc(#{rem($button-size-s)} - 2px);
        height: rem($button-size-s);
    }

    &--m{
        line-height: calc(#{rem($button-size-m)} - 2px);
        height: rem($button-size-m);
        padding: 0 $spacing-l;
    }

    &--l{
        line-height: calc(#{rem($button-size-l)} - 2px);
        height: rem($button-size-l);
        padding: 0 $spacing-l;
    }

    &--xl{
        line-height: calc(#{rem($button-size-xl)} - 2px);
        height: rem($button-size-xl);
        padding: 0 $spacing-xl;
    }

    &--wide{
        min-width: rem(150);
    }

    &--wider{
        min-width: rem(240);
    }

    &--stretch{
        display: block;
        width: 100%;

        & + .button{
            margin-left: 0;
        }
    }

    &--spaced{
        margin-top: $spacing-s;
    }

    &--collapsed{
        padding-left: 0;
        padding-right: 0;

        &:focus{
            box-shadow: none;
        }
    }

    /* shape modifiers */
    &--square,
    &--circle{
        text-align: center;
        width: rem($button-size);
        padding: 0;

        &.button--xxs{  width: rem($button-size-xxs);}
        &.button--xs{   width: rem($button-size-xs); }
        &.button--s{    width: rem($button-size-s);  }
        &.button--m{    width: rem($button-size-m);  }
        &.button--l{    width: rem($button-size-l);  }
        &.button--xl{   width: rem($button-size-xl); }
    }

    &--circle{
        border-radius: 50%;
    }

    &--round{
        border-radius: rem((0.5 * $button-size));

        &.button--xxs{  border-radius: rem((0.5 * $button-size-xxs));}
        &.button--xs{   border-radius: rem((0.5 * $button-size-xs)); }
        &.button--s{    border-radius: rem((0.5 * $button-size-s));  }
        &.button--m{    border-radius: rem((0.5 * $button-size-m));  }
        &.button--l{    border-radius: rem((0.5 * $button-size-l));  }
        &.button--xl{   border-radius: rem((0.5 * $button-size-xl)); }
    }

    &__icon{
        height: 0.8em;
        width: auto;
        margin-right: 0.4em;
        vertical-align: initial;

        &--end{
            margin-right: 0;
            margin-left: 0.4em;
        }

        &--only{
            display: block;
            height: 1em;
            margin-left: auto;
            margin-right: auto;
        }
    }

}
