@import '../../../../scss/partials';

.nav{
    &__item{
        display: inline-block;

        vertical-align: middle;

        font-size: rem(15);
        font-weight: 400;

        margin-left: $spacing-s;

        &__loader{
            height: 50px;
        }
    }

    &__mobile-icon{
        display: block;
        margin: 0 auto;

        &__loader{
            height: 50px;
        }
    }

    &__user{
        position: relative;

        &__picture{
            vertical-align: middle;
            width: rem(36);
            height: rem(36);
            object-fit: contain;
            &--border{
                border: 1px solid $c-border;
            }
        }
        &__icon{
            margin-left: $spacing-s;
        }
    }

}

@media (min-width: $bp-portrait){
    .nav{

        &__item{
            padding: 0 $spacing-s;
        }
    }
}
