@import '../../../scss/partials';

.menu {
    &__image,
    &__button {
        flex: 0 0 auto;
    }

    &__image {
        width: rem(36);
        height: rem(36);

        &--contain {
            object-fit: contain;
        }

        &--empty {
            border-radius: 50%;
            background-color: $gray-1;
        }

        &--border {
            border: 1px solid $c-border;
        }
    }

    &__text {
        flex: 1 1 auto;
        margin: 0 0 0 $spacing-m;

        &__block {
            display: block;
        }
    }

    &__content {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 0;
        padding: $spacing-l $spacing-m;

        &__padded-content {
            padding-right: rem(28);
        }
    }

    &__link {
        border-left: 3px solid transparent;

        &:not(:disabled):hover {
            background-color: $gray-1;
            border-color: $c-border;
        }

        &--active,
        &--active:hover {
            border-color: $c-brand-primary;
        }
    }
}

.submenu {
    &__content {
        height: auto;
    }

    &__title {
        padding: $spacing-s $spacing-m;
        position: relative;
        border-bottom: 1px solid $c-border;
    }

    &__description {
        padding: 0 $spacing-m;
    }

    &__back-button {
        position: absolute;
        left: $spacing-m;
        font-size: rem(14);
    }

    &__footer {
        padding: $spacing-s $spacing-m;
        border-top: 1px solid $c-border;
    }
}

.dropdown {
    &__item {
        &::after {
            content: "";
            display: block;
            height: 1px;
            margin: 0 $spacing-m;
            background-color: $c-border;
        }
        &:last-child::after {
            display: none;
        }
    }
}

.sidemenu {
    &__link {
        color: $c-text-light;
        &--active {
            color: $c-text;
        }
    }
}
