@import '../../../scss/partials';

.nearby{

    &__item{
        padding: $default-shadow-blur 0 $default-shadow-blur $content-spacing;
        flex: 0 0 auto;
        min-width: 0.8 * $min-element-width;
        width: 33%;
        max-width: rem(340);
        &:last-child{
            padding-right: $content-spacing;
            width: calc(33% + #{$content-spacing});
            max-width: calc(#{rem(340)} + #{$content-spacing});
        }
    }

}

@media (min-width: $bp-desktop){

    .nearby{

        &__item{
            padding: $default-shadow-blur 0 $default-shadow-blur $content-spacing-desktop;
            &:last-child{
                padding-right: $content-spacing-desktop;
                width: calc(33% + #{$content-spacing-desktop});
                max-width: calc(#{rem(340)} + #{$content-spacing-desktop});
            }
        }
    }

}
