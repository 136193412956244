@import '../../../scss/partials';

.cartbutton{
    padding-left: $spacing-m;
    padding-right: $spacing-m;

    &:hover,
    &:focus,
    &:active{
        .cartbutton__price{
            border-color: $c-brand-primary;
        }
    }

    &__count{
        position: relative;

        &__number{
            position: absolute;

            width: auto;

            left: 21%;
            right: 6%;
            bottom: 38%;
        }
    }

    &__price{
        display: inline-block;
        line-height: 1;
        margin-left: $spacing-m;
        padding: $spacing-xs 0 $spacing-xs $spacing-m;

        border-left: 1px solid $c-brand-primary-dark;
    }
}
