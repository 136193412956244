@import "../../../scss/partials";

.header{
    position: fixed;

    top: 0;
    left: 0;

    width: 100%;

    z-index: 1000;

    &__grid{
        position: relative;
        z-index: 2;

        padding: $spacing-s $spacing-l;
        border-bottom: 1px solid $c-border;

        &--custom{
            padding: $spacing-s $spacing-l 0;
        }
    }

    &__logo{
        display: inline-block;
        vertical-align: middle;
    }

    &__search{
        flex-grow: 1;
        padding: 0 $spacing-l;
        margin: 0 $spacing-l;
    }

    &__mobile-search{
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 0;
        width: 100%;
        display: block;
        padding: $spacing-s;
        border-bottom: 1px solid $c-border;
        @include boxShadow();
    }

}

@media (min-width: $bp-desktop){
    .header{
        &__search{
            padding: 0;
        }
    }
}

@supports (position: sticky) {
    .header{
        position: sticky;
    }
}
