@import '../../../scss/partials';

.card{

    display: block;
    background-color: $c-background;
    padding: $spacing-s;
    border: 1px solid $c-border;
    height: rem(200);

    &--card,
    &--map{
        position: relative;
        border: 0;
        padding: 0;
    }

    &--card{
        height: 100%;
        background-color: transparent;
    }

    &--map{
        height: auto;
        width: rem(264);
        border-radius: $border-radius;
    }

    &--compact{
        height: auto;
    }

    &--card,
    &--list{
        &:hover{
            @include boxShadow();
        }
    }

    &--loading:hover{
        box-shadow: none;
    }

    &--product{
        height: rem(120);
    }

    &__gallery{
        position: relative;

        &--list{
            flex: 0 0 180px;
        }

        &--compact{
            flex: 0 0 160px;
            height: 140px;
        }

        &--map,
        &--card{
            width: 100%;
            border-radius: $border-radius $border-radius 0 0;
            overflow: hidden;
        }

        &--card{
            flex: 0 0 auto;
            padding-bottom: (100 * 24 / 39 + 0%); // ratio = 39:24
        }

        &--map{
            padding-bottom: (100 * 120 / 264 + 0%); // ratio 264:120
        }
    }

    &__content{
        min-width: 0;

        &--list{
            flex: 1 1 auto;
            padding: $spacing-xs $spacing-xs $spacing-xs 0;
            margin-left: $spacing-l;
        }

        &--compact{
            padding: $spacing-xs 0;
            margin-left: $spacing-l;
        }

        &--card,
        &--map{
            border-radius: 0 0 $border-radius $border-radius;
            background-color: $white;
        }

        &--card{
            flex-grow: 1;
            padding: $spacing-l;
            border-color: $c-border;
            border-style: solid;
            border-width: 0 1px 1px 1px;
        }

        &--map{
            padding: $spacing-m $spacing-s;
            border-bottom: 1px solid $gray-4;
        }
    }

    &__arrow{
        position: absolute;

        bottom: -9px;
        left: 50%;

        transform: translateX(-50%);

        width: 30px;
        height: 10px;

        &::before,
        &::after{
            content: "";
            position: absolute;
            border-style: solid;

            width: 0;
            height: 0;
        }

        &::before{
            top: 1px;
            left: -1px;

            border-width: 11px 16px 0 16px;
            border-color: $gray-4 transparent;
        }

        &::after{
            top: 0;
            left: 0;

            border-width: 10px 15px 0 15px;
            border-color: $white transparent;
        }
    }

}

.gallery{
    &__card{
        border: $c-border solid 1px;
    }
}

@media (min-width: $bp-portrait){

    .card{
        &__gallery{
            &--list{
                flex: 0 0 230px;
            }
        }
    }

}
