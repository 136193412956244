@import '../../../scss/partials';

.icon{
    $self: &;

    display: inline-block;
    object-fit: contain;
    vertical-align: middle;

    &,
    &--default{
        width: rem(19);
        height: rem(19);
    }

    &--small{
        width: rem(14);
        height: rem(14);
    }

    &--medium{
        width: rem(21);
        height: rem(21);
    }

    &--large{
        width: rem(32);
        height: rem(32);
    }

    &--extra-large{
        width: rem(36);
        height: rem(36);
    }

    &--huge{
        width: rem(62);
        height: rem(62);
    }

    &--inline{
        height: 1em;
        width: 1em;
        margin-right: 0.5em;
        vertical-align: inherit;

        &#{$self}--after{
            margin-right: 0;
            margin-left: 0.5em;
        }

        &#{$self}--small{
            height: 0.8em;
            width: 0.8em;
        }

        &#{$self}--large{
            width: 1.5em;
            height: 1.5em;
        }
    }
}
