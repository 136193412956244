@import '../../../scss/partials';

.gallery{

    overflow: hidden;

    &,
    &__image{
        position: absolute;

        left: 0;
        top: 0;

        width: 100%;
        height: 100%;
    }

    &__image{
        transform: translate3d(-100%, 0, 0);
        @include transition(transform, $transition-long, ease-out);

        &--active,
        &:only-child{
            transform: translate3d(0, 0, 0);
        }

        &--active ~ &{
            transform: translate3d(100%, 0, 0);
        }

        &--placeholder{
            background-color: $gray-1;
            padding: $spacing-xl;
            object-fit: contain;
            border: 1px solid $c-border;
        }
    }

    &__button{
        position: absolute;

        top: 50%;
        transform: translateY(-50%);

        &--prev{ left: $spacing-xs; }
        &--next{ right: $spacing-xs; }
    }
}
