/* * * * * * * * *
 * BRAND COLORS  *
 * * * * * * * * */
$c-brand-primary:               var(--primary-color);
$c-brand-primary-text:          var(--primary-color-text);
$c-brand-primary-light:         var(--primary-color-light);
$c-brand-primary-light-text:    var(--primary-color-light-text);
$c-brand-primary-dark:          var(--primary-color-dark);
$c-brand-primary-dark-text:     var(--primary-color-dark-text);
$c-brand-primary-faded:         var(--primary-color-faded);
$c-brand-primary-faded-text:    var(--primary-color-faded-text);

$c-brand-secondary:             var(--secondary-color);
$c-brand-secondary-text:        var(--secondary-color-text);
$c-brand-secondary-light:       var(--secondary-color-light);
$c-brand-secondary-light-text:  var(--secondary-color-light-text);
$c-brand-secondary-dark:        var(--secondary-color-dark);
$c-brand-secondary-dark-text:   var(--secondary-color-dark-text);
$c-brand-secondary-faded:       var(--secondary-color-faded);
$c-brand-secondary-faded-text:  var(--secondary-color-faded-text);

/* * * * * * * * * * * * * * *
 * SET DEFAULT BRAND COLORS  *
 * * * * * * * * * * * * * * */
$default-brand-color: #20f383; // skopei green

$color-mods: ('', '-light', '-dark', '-faded');
$default-colors: (
    p:              $default-brand-color,
    p-txt:          $white,
    p-light:        lighten($default-brand-color, 10%),
    p-light-txt:    $gray-blue,
    p-dark:         darken($default-brand-color, 10%),
    p-dark-txt:     $white,
    p-faded:        rgba($default-brand-color, 0.4),
    p-faded-txt:    $gray-blue,

    s:              $default-brand-color,
    s-txt:          $white,
    s-light:        lighten($default-brand-color, 10%),
    s-light-txt:    $gray-blue,
    s-dark:         darken($default-brand-color, 10%),
    s-dark-txt:     $white,
    s-faded:        rgba($default-brand-color, 0.4),
    s-faded-txt:    $gray-blue
);

@mixin brand-color-parameters($mod-map: $color-mods, $color-map: $default-colors){
    @each $mod in $mod-map{
        $priColVar: '--primary-color#{$mod}';
        $priTxtVar: '--primary-color#{$mod}-text';
        $secColVar: '--secondary-color#{$mod}';
        $secTxtVar: '--secondary-color#{$mod}-text';

        $priColName: 'p#{$mod}';
        $priTxtName: 'p#{$mod}-txt';
        $secColName: 's#{$mod}';
        $secTxtName: 's#{$mod}-txt';

        $priCol: map-get($color-map, $priColName);
        $priTxt: map-get($color-map, $priTxtName);

        $secCol: map-get($color-map, $secColName);
        $secTxt: map-get($color-map, $secTxtName);

        #{$priColVar}: $priCol;
        #{$priTxtVar}: $priTxt;
        #{$secColVar}: $secCol;
        #{$secTxtVar}: $secTxt;
    }
}
