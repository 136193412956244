@import '../../../scss/partials';

.modal{

    position: fixed;

    left: 0;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    width: auto;
    height: auto;

    min-width: $min-element-width;

    margin: $spacing-l $spacing-s $spacing-xl $spacing-s;
    padding: $spacing-m $spacing-l;

    z-index: 10000;

    background-color: $white;
    color: $black;

    border: 0;
    border-radius: $border-radius;

    @include transition();
    @include boxShadow(0, 3px, 3px, 0, $shadow);

    &__title{
        .modal__close-button + &{
            margin-right: $spacing-xl;
        }
    }

    &__content{
        max-height: 70vh;
        overflow: auto;

        .modal__title + &{
            margin-top: $spacing-l;
        }
    }

    &__close-button{
        position: absolute;

        top: $spacing-m;
        right: $spacing-l;
    }

    animation: modal-show $transition-short ease-out reverse;

    &[open]{
        animation: modal-show $transition-short ease-out;
    }

    &--overflow{
        .modal__content{
            overflow: visible;
        }
    }

    &::backdrop{
        display: none;
    }

    // fix for browsers which don't support HTML Dialog elements
    &--legacy{
        display: none;

        &.modal--open{
            display: block;
        }
    }
}

.pageswitcher{
    $self: &;

    &__item{
        margin: 0 $spacing-s $spacing-l 0;
    }

    &__button{
        width: 100%;
        padding: $spacing-s 0;
        border-bottom: 1px solid $c-border;

        &:hover:not(:disabled){
            border-color: $c-border-dark;
        }
    }

}

.multipage{
    &__content{
        padding-bottom: $spacing-l;
        flex: 0 1 auto;
        overflow: auto;
    }

    &__footer{
        position: relative;

        padding-top: $spacing-m;
        border-top: 1px solid $c-border;

        &::after{
            content: "";
            position: absolute;

            left: 0;
            bottom: calc(100% + 1px);

            width: 100%;
            height: $spacing-xl;

            background-image: linear-gradient(to top, $white, transparent);
        }
    }
}

@media (min-width: $bp-xxs){

    .modal{
        width: $modal-width;

        margin-left: auto;
        margin-right: auto;

        padding: $spacing-xl $spacing-xxl;

        @include boxShadow();

        &--large{
            width: 100%;
            max-width: $modal-width-large;
        }

        &__close-button{
            top: $spacing-l;
        }

    }

}

@keyframes modal-show{
    from{   transform: translate(0, -50%) scale(0.8); opacity: 0; }
    to{     transform: translate(0, -50%) scale(1);   opacity: 1; }
}
