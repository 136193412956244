@import '../../../scss/partials';

.footer{
    padding: $spacing-xxl 0;

    &__logo{
        &__img{
            margin: 0 auto;
            height: rem($button-size-m);
        }
    }

    &__columns{
        margin: $spacing-xxl 0 $spacing-l 0;
        padding: $spacing-xl 0 0 0;
        border-style: solid;
        border-color: $gray-2;
        border-width: 1px 0;
    }

    &__column{
        padding: 0 $spacing-m 0 0;
        margin-bottom: $spacing-l;
        &__title{
            margin-bottom: $spacing-s;
        }
    }
}

@media (min-width: $bp-portrait){
    .footer{
        &__column{
            padding: 0 $spacing-l 0 0;
        }
    }
}

@media (min-width: $bp-desktop){
    .footer{
        &__column{
            padding: 0 $spacing-l 0 0;
        }
    }
}
