body {
    font-family: "azo-sans-web", sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: (28 / $base-font-size);
    background-color: $c-background;
    color: $c-text;
    min-width: $min-element-width;
	overflow-x: hidden;
}

abbr {
    border-bottom: 0;
    cursor: inherit;
    text-decoration: none;
}

hr {
    border: 0;
    margin: $spacing-l 0;
    height: 1.5em;

    display: flex;
    align-items: center;

    // line
    &::after {
        content: '';
        flex: 0 1 100%;
        height: 1px;
        background-color: $c-border;
    }

    // label
    &[data-content] {
        &::before {
            flex: 1 0 auto;
            content: attr(data-content);
            padding: 0 $spacing-xl 0 $spacing-xs;
            color: $c-text-light;
            white-space: nowrap;
        }
    }
}

main,
picture,
figure {
    display: block;
}

picture,
figure {
    img {
        display: block;
        width: 100%;
        height: 100%;
    }
}

img {
    object-fit: cover;
    &::selection {
        background: transparent;
    }
}

address {
    font-style: normal;
}
