@import '../../../../scss/partials';

.search{

    position: relative;

    width: 100%;
    max-width: rem(480);

    margin: 0 auto;
    padding: 1px;

    border: 1px solid $c-border;

    border-radius: $border-radius;

    &__box{
        width: 100%;
    }

    &__label{
        display: none;

        line-height: rem(48);
        height: rem(48);

        vertical-align: middle;

        padding: 0 0 0 $spacing-s;

        cursor: pointer;
    }

    &__button{
        &[disabled]{
            opacity: 1;
        }

        &--remove{
            margin-right: $spacing-m;
        }
    }

    &__input{
        flex-grow: 1;
    }

    &__tags-container{
        margin: 0 0 0 $spacing-s;
    }

    &__tag{
        margin-right: $spacing-s;

        &:last-child{
            margin-right: 0;
        }
    }

}

@media (min-width: $bp-xs){

    .search{
        &__label{
            display: inline-block;
        }
    }

}
