fieldset{
	border: 0;
    &:not(:last-child){
        margin-bottom: $spacing-xl;
    }
}

legend{
    font-size: rem(20);
    font-weight: 500;
    margin-bottom: $spacing-l;
}

label{
    &[for]{
        cursor: pointer;
    }
}

/* * * * * * * * * * *
 * TEXT INPUT STYLES *
 * * * * * * * * * * */
$input_types_text: 'date', 'datetime', 'datetime-local', 'email', 'month', 'number', 'password', 'range', 'search', 'tel', 'text', 'time', 'url', 'week';
@each $type in $input_types_text{
    input[type=#{$type}]{
		@include appearance(textfield);
    }
}

::-webkit-search-decoration{
	display:none;
}

::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
    @include appearance();
    margin: 0;
}

/* * * * * * * * * * * * *
 * CONTROL INPUT STYLES  *
 * * * * * * * * * * * * */
$input_types_controls: 'radio', 'checkbox';
@each $type in $input_types_controls{
    input[type=#{$type}]{

    }
}

/* * * * * * * * * * *
 * FILE INPUT STYLES *
 * * * * * * * * * * */
$input_types_file: 'file';
@each $type in $input_types_file{
    input[type=#{$type}]{

    }
}

::-webkit-file-upload-button{
	padding:0;
	border:0;
	background:none;
}

/* * * * * * *
 * TEXTAREAS *
 * * * * * * */
 textarea{
     overflow:auto;
 }

 /* * * * * *
  * SELECTS *
  * * * * * */
select{
	display: none;
    &[multiple]{

    }
}

/* * * * * *
 * BUTTONS *
 * * * * * */
button,
input[type="reset"],
input[type="button"],
input[type="submit"]{

}
